import React from 'react';
import {useEffect, useState} from 'react';


export default function CoName() {
  const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const code = urlParams.get('code');

  const [data, setData] = useState<any[]>([]);
  const [loading,setLoading] =useState(true);
  const [error,setError] = useState(null);
  
  
   useEffect(()=>{
const fetchData = async () => {
    const response = await fetch('https://api.ghlessentials.com/vidcon/namelogo.php?n=1&h='+window.location.hostname,{method: 'GET'});
    const newData = await response.json();
   // for(let i=0;i<newData.length;i++){
    //if(newData[i].acf.video_con_domain.indexOf(window.location.hostname)>-1){
    await setData(newData.name);
    document.documentElement.style.setProperty('--logo-color', newData.color);
   // }  
   // }
    
  };

  fetchData();

 }, []);
 
if (data) {
    return <div>{data}</div>;
  } else {
    return null;
  }
  
 

  
}
