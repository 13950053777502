import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  if(window.location.href.indexOf('admin=1')>-1){
    return (
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Join a Meeting
        </Typography>
        <Typography variant="body1">
          {hasUsername
            ? "Enter the name of a room you'd like to join."
            : "Enter your name and click continue to join"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className={classes.inputContainer}>
            {!hasUsername && (
              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-user-name">
                  Your Name
                </InputLabel>
                <TextField
                  id="input-user-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
            )}
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-room-name">
                Meeting ID
              </InputLabel>
              <TextField
                autoCapitalize="false"
                id="input-room-name"
                variant="outlined"
                fullWidth
                size="small"
                value={roomName}
                onChange={handleRoomNameChange}
            
              />
            </div>
          </div>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!name || !roomName}
              className={classes.continueButton}
            >
              Continue
            </Button>
          </Grid>
        </form>
      </>
    );


  }else{
    if(window.location.href.indexOf('name=')>-1){
      var url_string = window.location.href; 
var url = new URL(url_string);
var c = url.searchParams.get("name");
if(c!==null){
      setName(c);
      return (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Hey {name}
          </Typography>
          <Typography variant="body1">
            {hasUsername
              ? "Enter the name of a room you'd like to join."
              : "Click continue to join your meeting"}
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className={classes.inputContainer}>
              {!hasUsername && (
                <div className={classes.textFieldContainer} style={{display:'none'}}>
                  <InputLabel shrink htmlFor="input-user-name">
                    Your Name
                  </InputLabel>
                  <TextField
                    id="input-user-name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={name}
                    onChange={handleNameChange}
                  />
                </div>
              )}
              <div className={classes.textFieldContainer} style={{display:'none'}}>
                <InputLabel shrink htmlFor="input-room-name">
                  Meeting ID
                </InputLabel>
                <TextField
                  autoCapitalize="false"
                  id="input-room-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={roomName}
                  onChange={handleRoomNameChange}
                  disabled
                />
              </div>
            </div>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!name || !roomName}
                className={classes.continueButton}
              >
                Continue
              </Button>
            </Grid>
          </form>
        </>
      );
}
    }
    
    return (
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Join a Meeting
        </Typography>
        <Typography variant="body1">
          {hasUsername
            ? "Enter the name of a room you'd like to join."
            : "Enter your name and click continue to join"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className={classes.inputContainer}>
            {!hasUsername && (
              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-user-name">
                  Your Name
                </InputLabel>
                <TextField
                  id="input-user-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
            )}
            <div className={classes.textFieldContainer} style={{display:'none'}}>
              <InputLabel shrink htmlFor="input-room-name">
                Meeting ID
              </InputLabel>
              <TextField
                autoCapitalize="false"
                id="input-room-name"
                variant="outlined"
                fullWidth
                size="small"
                value={roomName}
                onChange={handleRoomNameChange}
                disabled
              />
            </div>
          </div>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!name || !roomName}
              className={classes.continueButton}
            >
              Continue
            </Button>
          </Grid>
        </form>
      </>
    );


  }
}
