import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
var url_string = window.location.href; 
var url = new URL(url_string);
var c = url.searchParams.get("admin");
if(window.location.href.indexOf('admin=1')>-1){
  return (
    <Button onClick={() => endm(room?.name)} className={clsx(classes.button, props.className)} data-cy-disconnect>
      End Meeting
    </Button>
  );
}else{
  return (
    <Button onClick={() => room!.disconnect()} className={clsx(classes.button, props.className)} data-cy-disconnect>
      Disconnect
    </Button>
  );
  
}
}

function endm(l?: string ){
fetch('https://api.ghlessentials.com/vidcon/endmeeting.php?h='+window.location.hostname+'&room='+ l);

}
