import React from 'react';
import {useEffect, useState} from 'react';


export default function VideoLogo() {
  const [data, setData] = useState(null);
  const [loading,setLoading] =useState(true);
  const [error,setError] = useState(null);
  
var imid;  
  
 useEffect(()=>{
const fetchData = async () => {
    const response = await fetch('https://api.ghlessentials.com/vidcon/namelogo.php?l=1&h='+window.location.hostname,{method: 'GET'});
    const newData = await response.json();
   // for(let i=0;i<newData.length;i++){
    //if(newData[i].acf.video_con_domain.indexOf(window.location.hostname)>-1){
   // const res = await fetch('https://ghlessentials.com/wp-json/wp/v2/media/'+newData[i].acf.ghl_logo,{method: 'GET',headers: {'Authorization': 'Basic dXNlcjYzMThmYWI4MWIwYTk6UWN2ZiBNY0o4IE1iOVogSGVpNyBtUUxxIEpBV2E=',},});
   // const nd = await res.json();
    setData(newData.link);
   // }  
   // }
    
  };

  fetchData();

 }, []);
 
if (data) {
    return <img width="50" src={data} />;
  } else {
    return null;
  }
  
}
